import React from "react"
import Img from "gatsby-image"
import {
  Typography,
  Container,
  Grid,
  Box,
  Paper,
  Theme,
  makeStyles,
} from "@material-ui/core"

import useWhoContent from "../hooks/use-who-content"

const useStyles = makeStyles((theme: Theme) => ({
  image: {
    order: 1,
    width: "100%",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      order: 2,
    },
  },
  text: {
    order: 2,
    [theme.breakpoints.down("sm")]: {
      order: 1,
    },
  },
}))

export const SectionWho = () => {
  const { title, html, image, imageAlt } = useWhoContent()
  const classes = useStyles()
  return (
    <Container>
      <Box display="flex" justifyContent="center">
        <Grid
          container
          spacing={8}
          alignItems="center"
          style={{ maxWidth: "1000px" }}
        >
          <Grid item md={5} className={classes.image}>
            {image && (
              <Paper elevation={5}>
                <Img fluid={image} alt={imageAlt} />
              </Paper>
            )}
          </Grid>
          <Grid item md={7} className={classes.text}>
            <div>
              <Typography variant="h2">{title}</Typography>
              <div
                dangerouslySetInnerHTML={{
                  __html: html,
                }}
              />
            </div>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}
