import React from "react"
import { createStyles, makeStyles, Theme } from "@material-ui/core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"

import useHomeContent from "../hooks/use-home-content"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    videoWrapper: {
      position: "relative",
      clipPath: "polygon(0 0, 100% 0, 100% 99%, 0 90%)",
    },
    video: {
      width: "100%",
      height: "95vh",
      objectFit: "cover",
    },
    overlay: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: "60%",
      background: "linear-gradient(to bottom, #ffffff73, transparent)",
    },
    readmoreBtn: {
      color: "#fff",
      position: "absolute",
      bottom: "10vh",
      left: "50%",
      transform: "translateX(-50%)",
      textAlign: "center",
      fontSize: "20px",
      fontWeight: 500,
    },
    readmoreBtnArrow: {
      animation: "$pulse 2s infinite",
    },
    "@keyframes pulse": {
      "0%": {
        transform: "translate(0, 0)",
      },
      "50%": {
        transform: "translate(0, 15px)",
      },
      "100%": {
        transform: "translate(0, 0)",
      },
    },
  })
)

export const SectionHomeHero = () => {
  const classes = useStyles()
  const { hero_video, hero_image } = useHomeContent()
  return (
    <div className={classes.videoWrapper}>
      <div className={classes.overlay}></div>
      <video
        poster={hero_image}
        className={classes.video}
        src={hero_video}
        autoPlay
        loop
        muted
        playsInline
      />
      <AnchorLink to="/#why" className={classes.readmoreBtn}>
        See more
        <div className={classes.readmoreBtnArrow}>
          <FontAwesomeIcon icon={faChevronDown} />
        </div>
      </AnchorLink>
    </div>
  )
}
