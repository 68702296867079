import React from "react"
import { Typography, Container, Grid, Box } from "@material-ui/core"

import useWhatContent from "../hooks/use-what-content"

export const SectionWhat = () => {
  const { title, html } = useWhatContent()
  return (
    <Container>
      <Grid container justify="center">
        <Grid item md={6}>
          <Box textAlign="center">
            <Typography variant="h2">{title}</Typography>
            <div dangerouslySetInnerHTML={{ __html: html }} />
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}
