import React from "react"
import { Box, Container, Typography } from "@material-ui/core"

import useVideosContent from "../hooks/use-videos-content"
import VideoList from "./video-list"
import { VideoListItemProps } from "./video-list-item"

export const SectionVideos = () => {
  const { videos, title, html } = useVideosContent()
  const videoItems: VideoListItemProps[] = videos.map(video => ({
    videoId: video.id,
    videoUrl: video.url,
  }))
  return (
    <>
      <Container maxWidth="lg" data-sal="zoom-out">
        <Box>
          <div style={{ maxWidth: "700px" }}>
            <Typography variant="h1">{title}</Typography>
            <div dangerouslySetInnerHTML={{ __html: html }} />
          </div>
        </Box>
      </Container>
      <div data-sal="slide-up">
        <VideoList items={videoItems} />
      </div>
    </>
  )
}
