import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import BlogListItem, { BlogListItemData } from "./blog-list-item"
import { Box, Button, Typography } from "@material-ui/core"
import Slider from "react-slick"
import { slickSettings, filenameToTitle } from "../utils"

function getWindowDimensions() {
  //const { innerWidth: width, innerHeight: height } = window;

  let height;
  let width;

  if (typeof window !== `undefined`) {
    height = window.innerHeight
    width = window.innerWidth
  }
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export default function LatestBlogItems() {
  const [activeItem, setActiveItem] = useState("")
  const { allMarkdownRemark } = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: {
          fields: { collection: { eq: "work" } }
          frontmatter: { published: { ne: false } }
        }
        sort: { fields: [frontmatter___date], order: DESC }
        limit: 4
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              excerpt
              date
              video
              thumbnail {
                name
                childImageSharp {
                  fixed(width: 500) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
            excerpt
            timeToRead
          }
        }
      }
    }
  `)

  const { height, width } = useWindowDimensions();

  const blogItems: BlogListItemData[] = allMarkdownRemark.edges.map(
    ({ node }) => {
      const { frontmatter } = node
      const { slug } = node.fields
      const { thumbnail, video, title, excerpt, date } = frontmatter
      const item: any = {}
      item.image = thumbnail.childImageSharp.fixed
      item.alt = filenameToTitle(thumbnail.name)
      item.videoUrl = video
      item.excerpt = excerpt
      item.title = title
      item.date = date
      item.slug = slug
      return item
    }
  )

  const slickOptions: any = {
    infinite: false,
    centerMode: false,
    variableWidth: false,
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          centerMode: true,
          infinite: false,
          centerPadding: "100px",
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          centerMode: true,
          infinite: false,
          centerPadding: "60px",
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
          centerMode: true,
          infinite: false,
          centerPadding: "60px",
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 2,
          centerMode: true,
          infinite: false,
          centerPadding: "30px",
        },
      },
    ],
  }

  if (width >= 1024){
    slickOptions.initialSlide = 0;
  }else{
    slickOptions.initialSlide = 1;
  }


  return (
    <>
      <div className="latest-work-slider">
        <Slider {...slickSettings(slickOptions)}>
          {blogItems.map((item, i) => (
            <Box p={{ md: 4 }} key={"bloglatest-" + i}>
              <Link to={item.slug}>
                <BlogListItem
                  {...item}
                  isActive={activeItem == item.slug}
                  onMouseEnter={() => setActiveItem(item.slug)}
                  onMouseLeave={() => setActiveItem("")}
                />
              </Link>
            </Box>
          ))}
        </Slider>
      </div>
      <Box my={6} display="flex" justifyContent="center">
        <Link to="/work">
          <Button
            component="div"
            color="primary"
            variant="contained"
            style={{ display: "inline-flex", alignItems: "center" }}
          >
            <Typography>See more work</Typography>
          </Button>
        </Link>
      </Box>
    </>
  )
}
