import React from "react"
import Img from "gatsby-image"
import { Typography, Container, Grid, Box, Paper } from "@material-ui/core"

import useWhyContent from "../hooks/use-why-content"

export const SectionWhy = () => {
  const { title, html, image, imageAlt } = useWhyContent()
  return (
    <Container>
      <Box display="flex" justifyContent="center">
        <Grid
          container
          spacing={8}
          alignItems="center"
          style={{ maxWidth: "1000px" }}
        >
          <Grid item md={7} data-sal="slide-left">
            <div>
              <Typography variant="h2">{title}</Typography>
              <div dangerouslySetInnerHTML={{ __html: html }} />
            </div>
          </Grid>
          <Grid item md={5} style={{ width: "100%" }} data-sal="slide-right">
            <Paper elevation={5}>
              <Img fluid={image} alt={imageAlt} />
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}
