import React, { useState } from "react"
import { Box } from "@material-ui/core"
import Slider from "react-slick"
import FsLightbox from "fslightbox-react"

import { slickSettings } from "../utils"
import VideoListItem, { VideoListItemProps } from "./video-list-item"

export interface VideoListData {
  items: VideoListItemProps[]
}

export default function VideoList({ items }: VideoListData) {
  const [activeVideo, setActiveVideo] = useState("")
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  })

  const openLightbox = (index: number) => {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: index + 1,
    })
  }

  const videoUrls: string[] = items.map(item => {
    return item.videoUrl
  })

  const sliderSettings: any = {
    initialSlide: 1,
    infinite: false,
  }

  if (items.length > 4) {
    sliderSettings.centerMode = false
    sliderSettings.slidesToScroll = 3
  }

  return (
    <>
      <Slider {...slickSettings(sliderSettings)}>
        {items.map((item, i) => (
          <Box
            py={7}
            px={1}
            key={"videoitem-" + i}
            onClick={() => openLightbox(i)}
          >
            <VideoListItem
              videoId={item.videoId}
              videoUrl={item.videoUrl}
              isActive={activeVideo == item.videoUrl}
              onMouseEnter={() => setActiveVideo(item.videoUrl)}
              onMouseLeave={() => setActiveVideo("")}
            />
          </Box>
        ))}
      </Slider>
      <FsLightbox
        disableThumbs={true}
        toggler={lightboxController.toggler}
        slide={lightboxController.slide}
        sources={videoUrls}
      />
    </>
  )
}
