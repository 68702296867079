import React from "react"
import { Box, Container, Grid, Button, Typography } from "@material-ui/core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faYoutube,
  faInstagram,
  faVimeo,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons"
import useContactContent from "../hooks/use-contact-content"
import { faEnvelope, faPhoneAlt } from "@fortawesome/free-solid-svg-icons"
import Flag from "./flag"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { useTheme } from "@material-ui/styles"
import useImagesContent from "../hooks/use-images-content"
import Img, { FixedObject } from "gatsby-image"

const MultiContact = (props: {
  icon?: IconProp
  image?: FixedObject
  imageAlt?: string
  urlPrefix: string
  title: string
  en: string
  th: string
  mt: number
}) => {
  const theme: any = useTheme()
  const graphic = props.icon ? (
    <FontAwesomeIcon
      size="4x"
      icon={props.icon}
      color={theme.palette.primary.main}
      style={{ marginBottom: "15px" }}
    />
  ) : (
    <Img
      fixed={props.image}
      alt={props.imageAlt}
      style={{ marginBottom: "15px" }}
    />
  )
  return (
    <Box
      mt={props.mt}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      {graphic}
      <Button href={`${props.urlPrefix}${props.en}`} title={props.title}>
        <h3 style={{ margin: 0, display: "flex", justifyContent: "center" }}>
          <div style={{ marginRight: "5px" }}>
            <Flag country="uk" />
          </div>{" "}
          {props.en}
        </h3>
      </Button>
      <Button href={`${props.urlPrefix}${props.th}`} title={props.title}>
        <h3 style={{ margin: 0, display: "flex", justifyContent: "center" }}>
          <div style={{ marginRight: "5px" }}>
            <Flag country="th" />
          </div>{" "}
          {props.th}
        </h3>
      </Button>
    </Box>
  )
}

const SocialLink = (props: {
  icon: IconProp
  url: string
  label: string
  color: string
}) => {
  return (
    <Button
      href={props.url}
      style={{ justifyContent: "flex-start", marginLeft: "100px" }}
    >
      <h3 style={{ margin: 0, display: "flex", alignItems: "center" }}>
        <div style={{ marginRight: "5px" }}>
          <FontAwesomeIcon size="2x" color={props.color} icon={props.icon} />
        </div>{" "}
        {props.label}
      </h3>
    </Button>
  )
}

export const SectionContact = () => {
  const {
    title,
    html,
    email,
    phone_english,
    phone_thai,
    whatsapp_english,
    whatsapp_thai,
    youtube_label,
    youtube_url,
    instagram_label,
    instagram_url,
    vimeo_label,
    vimeo_url,
    facebook_label,
    facebook_url,
  } = useContactContent()
  const { whatsappAppicon } = useImagesContent()
  const theme: any = useTheme()
  return (
    <Container data-sal="zoom-out">
      <Grid container justify="center">
        <Grid item md={6}>
          <Box textAlign="center" mb={6}>
            <Typography variant="h2">{title}</Typography>
            <div dangerouslySetInnerHTML={{ __html: html }} />
          </Box>
        </Grid>
      </Grid>
      <Grid container style={{ justifyContent: "center" }}>
        <Grid item xs={12} md={3}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <FontAwesomeIcon
              size="4x"
              color={theme.palette.primary.main}
              icon={faEnvelope}
              style={{ marginBottom: "15px" }}
            />
            <Button href={`mailto:${email}`} title={"Email address"}>
              <h3 style={{ margin: 0 }}>{email}</h3>
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <MultiContact
            mt={5}
            icon={faPhoneAlt}
            urlPrefix={"tel:"}
            en={phone_english}
            th={phone_thai}
            title={"Call"}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <MultiContact
            mt={10}
            image={whatsappAppicon}
            imageAlt="Whatsapp logo"
            urlPrefix={"https://wa.me/"}
            en={whatsapp_english}
            th={whatsapp_thai}
            title={"Chat on Whatsapp"}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Box mt={10} display="flex" flexDirection="column">
            <SocialLink
              icon={faYoutube}
              label={youtube_label}
              url={youtube_url}
              color="#FF0000"
            />
            <SocialLink
              icon={faInstagram}
              label={instagram_label}
              url={instagram_url}
              color="#3f729b"
            />
            <SocialLink
              icon={faVimeo}
              label={vimeo_label}
              url={vimeo_url}
              color="#86c9ef"
            />
            <SocialLink
              icon={faFacebook}
              label={facebook_label}
              url={facebook_url}
              color="#3b5998"
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}
