import React from "react"

import { SectionHomeHero } from "../components/section-home-hero"
import { SectionWhy } from "../components/section-why"
import { SectionHow } from "../components/section-how"
import { SectionClients } from "../components/section-clients"
import { SectionPhotos } from "../components/section-photos"
import { SectionLatestWork } from "../components/section-latest-work"
import { SectionContact } from "../components/section-contact"
import { SectionVideos } from "../components/section-videos"
import { SectionWho } from "../components/section-who"
import SEO from "../components/seo"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import useSeoContent from "../hooks/use-seo-content"
import { SectionWhat } from "../components/section-what"
import DiagonalSection from "../components/diagonal-section"

export default function IndexPage({ location }) {
  const { home_meta_title, home_meta_description } = useSeoContent()
  return (
    <>
      <SEO
        location={location}
        title={home_meta_title}
        description={home_meta_description}
      />
      <SectionHomeHero />
      <section id="why">
        <SectionWhy />
      </section>
      <DiagonalSection id="who" side="left">
        <SectionWho />
      </DiagonalSection>
      <section id="what">
        <SectionWhat />
      </section>
      <DiagonalSection id="how" side="right">
        <SectionHow />
      </DiagonalSection>
      <section id="photos">
        <SectionPhotos />
      </section>
      <DiagonalSection id="videos" side="left">
        <SectionVideos />
      </DiagonalSection>
      <section id="clients">
        <SectionClients />
      </section>
      <DiagonalSection id="work" side="right">
        <SectionLatestWork />
      </DiagonalSection>
      <section id="contact">
        <SectionContact />
      </section>
    </>
  )
}
