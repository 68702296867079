import { graphql, useStaticQuery } from "gatsby"
import { FluidObject } from "gatsby-image"
import { filenameToTitle } from "../utils"

export default function useWhyContent() {
  const {
    file: {
      childMarkdownRemark: {
        html,
        frontmatter: { title, image },
      },
    },
  } = useStaticQuery(graphql`
    query {
      file(sourceInstanceName: { eq: "data" }, name: { eq: "why" }) {
        childMarkdownRemark {
          html
          frontmatter {
            title
            image {
              name
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  `)
  return {
    title,
    html,
    image: image?.childImageSharp?.fluid as FluidObject,
    imageAlt: image?.name ? filenameToTitle(image.name) : "",
  }
}
