import React, { useState, createRef, useEffect } from "react"
import { Paper, makeStyles, Theme } from "@material-ui/core"
import Axios from "axios"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlay } from "@fortawesome/free-solid-svg-icons"

const height = 300
const width = (height / 9) * 16

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    overflow: "hidden",
    height: height,
    width: width,
    transition: ".5s",
    position: "relative",
    "&:hover": {
      zIndex: 99,
      transform: "scale(1.1)",
      boxShadow: theme.shadows[12],
    },
    [theme.breakpoints.down("md")]: {
      height: "180px",
      width: "auto",
    },
  },
  video: {
    objectFit: "cover",
    height: "100%",
  },
  playBtn: {
    position: "absolute",
    top: "50%",
    left: "50%",
    background: "radial-gradient(#ffffff 0%, transparent 70%)",
    padding: "20px 20px 20px 25px",
    transform: "translate(-50%, -50%)",
  },
}))

export interface VideoListItemProps {
  videoUrl: string
  videoId: string
  isActive?: boolean
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}

export default function VideoListItem(data: VideoListItemProps) {
  const classes = useStyles()
  const [controls, setControls] = useState(false)
  const [showPlayBtn, setShowPlayBtn] = useState(true)
  const [thumbnail, setThumbnail] = useState("")
  const videoRef = createRef<any>()

  useEffect(() => {
    if (data.isActive) {
      videoRef.current.play()
    } else {
      videoRef.current.pause()
    }
    if (!thumbnail) {
      Axios.get(`https://vimeo.com/api/v2/video/${data.videoId}.json`)
        .then(res => setThumbnail(res.data[0].thumbnail_large))
        .catch(err => console.log(`thumbnail not found for ${data.videoId}`))
    }
  }, [data.isActive])

  const onMouseEnter = () => {
    setControls(true)
    setShowPlayBtn(false)
    data.onMouseEnter()
  }

  const onMouseLeave = () => {
    setControls(false)
    setShowPlayBtn(true)
    data.onMouseLeave()
  }

  return (
    <Paper className={classes.paper}>
      <div className={classes.playBtn} hidden={!showPlayBtn}>
        <FontAwesomeIcon icon={faPlay} size="3x" />
      </div>
      <video
        muted
        preload="none"
        ref={videoRef}
        poster={thumbnail}
        src={data.videoUrl}
        className={classes.video}
        controls={controls}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
    </Paper>
  )
}
