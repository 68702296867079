import { graphql, useStaticQuery } from "gatsby"
import { FluidObject } from "gatsby-image"
import { filenameToTitle } from "../utils"

export default function useWhoContent() {
  const {
    file: {
      childMarkdownRemark: {
        html,
        frontmatter: { image, title },
      },
    },
  } = useStaticQuery(graphql`
    query {
      file(sourceInstanceName: { eq: "data" }, name: { eq: "who" }) {
        childMarkdownRemark {
          frontmatter {
            title
            image {
              name
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          html
        }
      }
    }
  `)
  return {
    html,
    title,
    image: image?.childImageSharp?.fluid as FluidObject,
    imageAlt: image?.name ? filenameToTitle(image.name) : "",
  }
}
