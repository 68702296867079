import React from "react"
import { Box, Container, Typography } from "@material-ui/core"

import usePhotosContent from "../hooks/use-photos-content"
import { PhotoListItemProps } from "./photo-list-item"
import PhotoList from "./photo-list"

export const SectionPhotos = () => {
  const { photos, title, html } = usePhotosContent()
  const photoItems: Partial<PhotoListItemProps>[] = photos.map(photo => ({
    image: photo.image,
    imageAlt: photo.alt,
    publicUrl: photo.publicURL,
    aspectRatio: photo.image.aspectRatio,
  }))

  return (
    <>
      <Container maxWidth="lg" data-sal="zoom-out">
        <Box>
          <div style={{ maxWidth: "700px" }}>
            <Typography variant="h1">{title}</Typography>
            <div dangerouslySetInnerHTML={{ __html: html }} />
          </div>
        </Box>
      </Container>
      <div>
        <PhotoList items={photoItems as PhotoListItemProps[]} />
      </div>
    </>
  )
}
