import React from "react"
import Img, { FluidObject } from "gatsby-image"
import { Paper, makeStyles, Theme, createStyles } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      overflow: "hidden",
      transition: ".5s",
      position: "relative",
      borderRadius: "0px",
      cursor: "pointer",
      "&:hover": {
        zIndex: 100000,
        transform: "scale(1.1)",
        boxShadow: theme.shadows[12],
      },
    },
    image: {
      minHeight: "100%",
    },
  })
)

export interface PhotoListItemProps {
  image: FluidObject
  imageAlt: string
  aspectRatio: number
  publicUrl: string
}

export default function PhotoListItem(data: PhotoListItemProps) {
  const classes = useStyles()

  return (
    <Paper className={classes.paper} style={{ height: "100%", width: "100%" }}>
      <Img fluid={data.image} className={classes.image} alt={data.imageAlt} />
    </Paper>
  )
}
