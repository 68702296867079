import React from "react"
import { makeStyles, Theme } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) => ({
  section: ({ side }: { side: string }) => ({
    position: "relative",
    "&:before": {
      zIndex: -1,
      borderTop: "3px solid #dedede",
      borderBottom: "3px solid #dedede",
      content: '""',
      position: "absolute",
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      backgroundImage: "linear-gradient(45deg, #f7f7f7, #efefef)",
      transform: side == "left" ? "skewY(-4deg)" : "skewY(4deg)",
    },
  }),
}))

export interface DiagonalSectionData {
  side: "left" | "right"
  id?: string
}

export default function DiagonalSection({ children, side, id = "" }) {
  const classes = useStyles({ side })
  return (
    <section className={classes.section} id={id}>
      <div className="content">{children}</div>
    </section>
  )
}
