import { graphql, useStaticQuery } from "gatsby"

export default function useHomeContent() {
  const {
    file: {
      childMarkdownRemark: {
        frontmatter: { hero_video, hero_image },
      },
    },
  } = useStaticQuery(graphql`
    query {
      file(sourceInstanceName: { eq: "data" }, name: { eq: "home" }) {
        childMarkdownRemark {
          frontmatter {
            hero_video
            hero_image {
              publicURL
            }
          }
        }
      }
    }
  `)
  return {
    hero_video,
    hero_image: hero_image.publicURL,
  }
}
