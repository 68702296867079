import { graphql, useStaticQuery } from "gatsby"
import { FluidObject } from "gatsby-image"
import { filenameToTitle } from "../utils"

export default function useClientsContent() {
  const {
    file: {
      childMarkdownRemark: {
        html,
        frontmatter: { title, clients },
      },
    },
  } = useStaticQuery(graphql`
    query {
      file(sourceInstanceName: { eq: "data" }, name: { eq: "clients" }) {
        childMarkdownRemark {
          html
          frontmatter {
            title
            clients {
              alt
              url {
                name
                publicURL
                childImageSharp {
                  fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid_withWebp
                    aspectRatio
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  return {
    title,
    html,
    clients: clients
      .filter(brand => brand.url)
      .map(brand => {
        return {
          publicURL: brand.url.publicURL,
          image: brand.url.childImageSharp.fluid,
          alt: brand.alt || filenameToTitle(brand.url.name),
        }
      }) as { publicURL: string; image: FluidObject; alt: string }[],
  }
}
