import React from "react"
import { Typography, Container, Box, Grid } from "@material-ui/core"

import useHowContent from "../hooks/use-how-content"

export const SectionHow = () => {
  const { title, html } = useHowContent()
  return (
    <Container>
      <Grid container justify="center">
        <Grid item md={6}>
          <Box textAlign="center">
            <Typography variant="h2">{title}</Typography>
            <div dangerouslySetInnerHTML={{ __html: html }} />
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}
