import React from "react"
import Img from "gatsby-image"
import {
  Container,
  Grid,
  Typography,
  makeStyles,
  Theme,
  createStyles,
  Box,
} from "@material-ui/core"

import useClientsContent from "../hooks/use-clients-content"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageWrapper: {
      height: "150px",
      width: "100%",
    },
  })
)

export const SectionClients = () => {
  const classes = useStyles()
  const { title, html, clients } = useClientsContent()
  return (
    <Container>
      <Grid container justify="center">
        <Grid item md={6}>
          <Box textAlign="center" mb={6}>
            <Typography variant="h2">{title}</Typography>
            <div dangerouslySetInnerHTML={{ __html: html }} />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={3} alignItems={"center"}>
        {clients.map((brand, i) => (
          <Grid item xs={4} md={2} key={"brand-" + i}>
            <Img
              imgStyle={{ objectFit: "contain" }}
              className={classes.imageWrapper}
              fluid={brand.image}
              alt={brand.alt}
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}
