import React from "react"
import { Box, Container, Typography } from "@material-ui/core"

import LatestBlogItems from "./latest-blog-items"
import useLatestWorkContent from "../hooks/use-latest-work-content"

export const SectionLatestWork = () => {
  const { title, html } = useLatestWorkContent()
  return (
    <>
      <Container data-sal="zoom-out">
        <Box mb={6} maxWidth="700px">
          <Typography variant="h1">{title}</Typography>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </Box>
      </Container>
      <Container maxWidth="xl" className="latest-work-container">
        <LatestBlogItems />
      </Container>
    </>
  )
}
