import React, { useState } from "react"
import { createStyles, Grid, makeStyles, Theme } from "@material-ui/core"
import Slider from "react-slick"
import FsLightbox from "fslightbox-react"
import { makeChunks } from "../utils"

import PhotoListItem, { PhotoListItemProps } from "./photo-list-item"
export interface PhotoListProps {
  items: PhotoListItemProps[]
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: ({ height, width }: { height: number; width: number }) => ({
      [theme.breakpoints.down("sm")]: {
        height: `${height / 1}px`,
        width: `100%`,
      },
      [theme.breakpoints.up("md")]: {
        height: `${height / 1}px`,
        width: `100%`,
      },
      [theme.breakpoints.up("lg")]: {
        height: `${height}px`,
        width: `${width}px`,
      },
    }),
  })
)

export default function PhotoList({ items }: PhotoListProps) {
  const maxRows = 3
  const itemWidth = 400
  const itemHeight = (itemWidth / 16) * 9
  const classes = useStyles({ height: itemHeight, width: itemWidth })
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  })

  const openLightbox = (index: number) => {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: index + 1,
    })
  }

  const imageUrls: string[] = items.map(item => {
    return item.publicUrl
  })

  const chunks = makeChunks(items, maxRows)

  const sliderSettings: any = {
    initialSlide: 0,
    infinite: false,
    centerMode: false,
    slidesToScroll: 4,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          variableWidth: false,
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          variableWidth: false,
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          variableWidth: false,
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  }

  return (
    <>
      <div className="photo-slider">
        <Slider {...sliderSettings}>
          {chunks.map((chunk, i) => {
            return (
              <div className="gallery-item" key={"chunk-" + i}>
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  spacing={1}
                  style={{ paddingLeft: "5px", paddingRight: "5px" }}
                >
                  {chunk.map((item, index) => (
                    <Grid
                      item
                      className={classes.item}
                    >
                      <div
                        key={"photolistitem-" + index}
                        onClick={() => openLightbox(i * maxRows + index)}
                        className={"photoOverlay"}
                      >
                      </div>
                        <PhotoListItem {...item} />
                    </Grid>
                  ))}
                </Grid>
              </div>
            )
          })}
        </Slider>
      </div>
      <FsLightbox
        disableThumbs={true}
        toggler={lightboxController.toggler}
        slide={lightboxController.slide}
        sources={imageUrls}
      />
    </>
  )
}
