import { graphql, useStaticQuery } from "gatsby"

export default function useVideosContent() {
  const {
    file: {
      childMarkdownRemark: {
        html,
        frontmatter: { title, videos },
      },
    },
  } = useStaticQuery(graphql`
    query {
      file(sourceInstanceName: { eq: "data" }, name: { eq: "videos" }) {
        childMarkdownRemark {
          html
          frontmatter {
            title
            videos {
              url
              id
            }
          }
        }
      }
    }
  `)
  return {
    title,
    html,
    videos: videos as { id: string; url: string }[],
  }
}
